const action = {
    sign_in: 'Entrar',
    continue: 'Continuar',
    create_account: 'Criar uma conta',
    create_account_without_linking: 'Criar um novo em vez disso',
    create: 'Criar',
    enter_passcode: 'Introduzir código de verificação',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    save_password: 'Guardar palavra-passe',
    bind: 'Agregar a {{address}}',
    bind_and_continue: 'Ligar e continuar',
    back: 'Voltar',
    nav_back: 'Anterior',
    nav_skip: 'Saltar',
    agree: 'Aceito',
    got_it: 'Entendi',
    sign_in_with: 'Continuar com {{name}}',
    forgot_password: 'Esqueceu-se da palavra-passe?',
    switch_to: 'Mudar para {{method}}',
    sign_in_via_passcode: 'Iniciar sessão com código de verificação',
    sign_in_via_password: 'Iniciar sessão com palavra-passe',
    change: 'Alterar {{method}}',
    link_another_email: 'Vincular outro email',
    link_another_phone: 'Vincular outro telefone',
    link_another_email_or_phone: 'Vincular outro email ou telefone',
    show_password: 'Mostrar palavra-passe',
    copy: 'Copiar',
    verify_via_passkey: 'Verificar através de chave de acesso',
    download: 'Transferir',
    single_sign_on: 'Logon Único',
    /** UNTRANSLATED */
    authorize: 'Authorize',
    /** UNTRANSLATED */
    use_another_account: 'Use another account',
};
export default Object.freeze(action);
